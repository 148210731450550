import { motion } from "framer-motion";
import { FaFacebook } from "react-icons/fa";

const TAS = () => {
  return (
    <section className="min-h-screen bg-[#0A0A0A] relative overflow-hidden">
      {/* Animated gradient overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.08 }}
        transition={{ duration: 2 }}
        className="absolute inset-0 pointer-events-none"
        style={{
          background: `
            radial-gradient(circle at 20% 20%, rgba(218, 165, 32, 0.15) 0%, transparent 60%),
            radial-gradient(circle at 80% 80%, rgba(255, 215, 0, 0.1) 0%, transparent 60%),
            radial-gradient(circle at 50% 50%, rgba(184, 134, 11, 0.05) 0%, transparent 70%)
          `,
        }}
      />

      {/* Main content */}
      <div className="relative z-10 container mx-auto px-8">
        <div className="min-h-screen flex flex-col justify-center">
          {/* Hero Header */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-24"
          >
            <div className="mb-6">
              <div className="h-[2px] w-12 bg-gradient-to-r from-[#FFD700] to-transparent" />
            </div>

            <h2
              className="font-light tracking-[0.5em] uppercase mb-6 text-[#B3B3B3]
                          desktop:text-lg laptop:text-base tablet:text-sm mobile:text-xs"
            >
              Professional Gear Sports Community
            </h2>

            <div className="relative">
              <h1
                className="font-black tracking-tight leading-none mb-8
                           desktop:text-[8.5rem] laptop:text-7xl tablet:text-6xl mobile:text-5xl"
              >
                <span
                  className="bg-gradient-to-r from-[#FFD700] via-[#DAA520] to-[#B8860B] 
                               bg-clip-text text-transparent"
                >
                  TAGUIG ALL STARS
                </span>
              </h1>
            </div>

            <p
              className="text-gray-400 max-w-2xl leading-relaxed font-light
                         desktop:text-2xl laptop:text-xl tablet:text-lg mobile:text-base"
            >
              A professional gear sports community playing BeybladeX in the
              Philippines.
            </p>

            <div className="mt-12">
              <a
                href="https://www.facebook.com/taguigallstars/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-[#FFD700]/40 hover:text-[#FFD700] transition-colors duration-300"
              >
                <FaFacebook className="w-8 h-8" />
              </a>
            </div>
          </motion.div>

          {/* Featured Image */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="w-full max-w-7xl mx-auto overflow-hidden rounded-xl"
          >
            <img
              src="https://createsiteai.b-cdn.net/c/_/5f6b2ac7-679f-4fd2-8a74-3b64dfadc5c0.jpg"
              alt="Taguig All Stars"
              className="w-full h-auto object-cover"
            />
          </motion.div>

          {/* Bottom decorative element */}
          <div className="absolute bottom-0 left-0 right-0">
            <div className="h-[1px] bg-gradient-to-r from-transparent via-[#FFD700]/10 to-transparent" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TAS;
