import { motion } from "framer-motion";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="bg-[#0A0A0A] py-8"
    >
      <div className="container mx-auto px-8">
        <div className="flex justify-center">
          <a
            href="https://www.facebook.com/blackbtv/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#FFD700]/40 hover:text-[#FFD700] transition-colors duration-300"
          >
            <FaFacebook className="w-8 h-8" />
          </a>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
