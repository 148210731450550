import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";

const Hero = () => {
  return (
    <section className="min-h-screen bg-[#0A0A0A] relative overflow-hidden">
      {/* Animated gradient overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.08 }}
        transition={{ duration: 2 }}
        className="absolute inset-0 pointer-events-none"
        style={{
          background: `
            radial-gradient(circle at 20% 20%, rgba(218, 165, 32, 0.15) 0%, transparent 60%),
            radial-gradient(circle at 80% 80%, rgba(255, 215, 0, 0.1) 0%, transparent 60%),
            radial-gradient(circle at 50% 50%, rgba(184, 134, 11, 0.05) 0%, transparent 70%)
          `,
        }}
      />

      {/* Main content */}
      <div className="relative z-10 container mx-auto px-8">
        <div className="min-h-screen flex flex-col justify-center">
          {/* Hero Header */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-24"
          >
            {/* Subtle decorative element */}
            <div className="mb-6">
              <div className="h-[2px] w-12 bg-gradient-to-r from-[#FFD700] to-transparent" />
            </div>

            <h2
              className="font-light tracking-[0.5em] uppercase mb-6 text-[#B3B3B3]
                        desktop:text-lg laptop:text-base tablet:text-sm mobile:text-xs"
            >
              Bound for Greatness
            </h2>

            <div className="relative">
              <h1
                className="font-black tracking-tight leading-none mb-8
                         desktop:text-[8.5rem] laptop:text-7xl tablet:text-6xl mobile:text-5xl"
              >
                <span
                  className="bg-gradient-to-r from-[#FFD700] via-[#DAA520] to-[#B8860B] 
                             bg-clip-text text-transparent"
                >
                  BLACKBEARD
                </span>
              </h1>
            </div>

            <p
              className="text-gray-400 max-w-2xl leading-relaxed font-light
                       desktop:text-2xl laptop:text-xl tablet:text-lg mobile:text-base"
            >
              Uniting community building, esports excellence, gear sports
              supremacy, talent management, wellness innovation, and strategic
              investments.
            </p>
          </motion.div>

          {/* Services Grid */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 
                     gap-10 w-full max-w-7xl"
          >
            {[
              {
                title: "Blackbeard Entertainment",
                description:
                  "Premier esports and gear sports talent agency in the Philippines.",
                path: "https://blackbeard.one/bbe",
                facebook: "https://www.facebook.com/BlackbeardDotOne/",
                image:
                  "https://createsiteai.b-cdn.net/c/_/3be597f6-f0df-4d78-b0fb-210e7c2f28cf.png",
              },
              {
                title: "Taguig All Stars",
                description:
                  "Professional gear sports community playing BeybladeX in the Philippines.",
                path: "https://blackbeard.one/tas",
                facebook: "https://www.facebook.com/taguigallstars/",
                image:
                  "https://createsiteai.b-cdn.net/c/_/5f6b2ac7-679f-4fd2-8a74-3b64dfadc5c0.jpg",
              },
              {
                title: "Body Central",
                description:
                  "Online shop for health and wellness products in the Philippines.",
                path: "https://blackbeard.one/bc",
                facebook: "https://www.facebook.com/hellobodycentral/",
                image:
                  "https://createsiteai.b-cdn.net/c/_/62a9b1dc-f19b-456e-b69e-f15cdae09f33.jpg",
              },
              {
                title: "Mox Cobalt",
                description:
                  "Online shop for toys, board games and card games.",
                path: "https://moxcobalt.com/",
                facebook: "https://www.facebook.com/moxcobalt/",
                image:
                  "https://createsiteai.b-cdn.net/c/_/0200ec50-999f-450f-86f9-84b90d44b15e.jpg",
              },
              {
                title: "Crypto Investments",
                description:
                  "Strategic blockchain and cryptocurrency investment initiatives.",
                path: "https://www.facebook.com/dalioan.joedel/",
                image:
                  "https://createsiteai.b-cdn.net/c/_/1e7a94e7-187c-4063-9fc9-e46cccd487c5.webp",
              },
            ].map((item, index) => (
              <a
                key={item.path}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                  className="overflow-hidden rounded-xl bg-[#111111] 
                            border border-[#FFD700]/5
                            hover:border-[#FFD700]/20
                            hover:bg-[#141414]
                            transition-all duration-500 ease-out"
                >
                  {/* Service number */}
                  <div className="aspect-w-16 aspect-h-9 mb-8 relative overflow-hidden">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full h-full object-cover"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-b from-transparent to-[#111111] opacity-90 
                                  group-hover:opacity-75 transition-opacity duration-500"
                    />
                  </div>
                  <div className="p-10">
                    <div
                      className="mb-8 font-light text-[#FFD700]/20 
                              group-hover:text-[#FFD700]/40 transition-colors duration-300
                              desktop:text-lg laptop:text-base tablet:text-base mobile:text-sm"
                    >
                      {(index + 1).toString().padStart(2, "0")}
                    </div>

                    <h3
                      className="text-white font-medium mb-4
                             desktop:text-3xl laptop:text-2xl tablet:text-2xl mobile:text-xl"
                    >
                      {item.title}
                    </h3>

                    <p
                      className="text-gray-400 font-light leading-relaxed mb-8
                             desktop:text-lg laptop:text-base tablet:text-base mobile:text-sm"
                    >
                      {item.description}
                    </p>

                    {item.facebook && (
                      <a
                        href={item.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-4 text-[#FFD700]/40 hover:text-[#FFD700] transition-colors duration-300"
                      >
                        <FaFacebook className="w-5 h-5" />
                      </a>
                    )}
                    {/* Animated arrow */}
                    <div
                      className="flex items-center text-[#FFD700]/40 
                              group-hover:text-[#FFD700] transition-colors duration-300"
                    >
                      <span className="text-sm tracking-wider uppercase mr-4">
                        Explore
                      </span>
                      <svg
                        className="w-5 h-5 transform group-hover:translate-x-2 transition-transform duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </div>
                  </div>
                </motion.div>
              </a>
            ))}
          </motion.div>

          {/* Bottom decorative element */}
          <div className="absolute bottom-0 left-0 right-0">
            <div className="h-[1px] bg-gradient-to-r from-transparent via-[#FFD700]/10 to-transparent" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
