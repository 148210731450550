import React from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  return (
    <>
      <Hero /> <Footer />
    </>
  );
};

export default Home;
